<template>
    <div class="demoPage">
        <div class="block">
            1234567890-
        </div>

        <div class="content_box">
            <van-sticky :offset-top="89">
                <van-button type="info">吸顶距离</van-button>
            </van-sticky>
            <div class="block"></div>
            <div ref="container" style="height: 150px;">
                <van-sticky :container="container">
                    <van-button type="warning">指定容器</van-button>
                </van-sticky>
            </div>
            <div class="block"></div>
        </div>


    </div>
</template>

<script>
    export default {
        name: "Demo",
        data() {
            return {
                container: null,
            };
        },
        mounted() {
            this.container = this.$refs.container;
        },
    }
</script>

<style lang="scss">
    .demoPage{
        @extend .flex_column;
        @extend .abs_full_screen;
    }
    .block{
        height: 300px;
        width: 100%;
        background: #00A0E8;
        &:last-child{
            height: 900px;
        }
    }
    .content_box{
        position: relative;
        @extend .flex_height_auto;
    }

</style>